<template>
  <div class="sidebar">
    <div class="sidebanner" @click="openShop">
      <img :src="require('../../assets/images/'+(isMobile?'mobile/shop-banner.webp':'shop-banner.webp'))" alt="" style="width: 100%; height: 100%; min-height: 125px;"/>
      <p class="sidebanner-text">Gamers Shop</p>
      <div class="button-basic-tickets-menu-btn sidebanner-button orange">
        Open
      </div>
    </div>
    <div class="sidebanner" @click="openGuide">
      <img :src="require('../../assets/images/'+(isMobile?'mobile/winnfts-banner.webp':'winnfts-banner.webp'))" alt="" style="width: 100%; min-height: 125px;" />
      <p class="sidebanner-text">How to win nfts?</p>
      <div class="button-basic-tickets-menu-btn sidebanner-button green">
        Learn
      </div>
    </div>
    <div class="sidebanner" @click="openRedeem">
      <img :src="require('../../assets/images/'+(isMobile?'mobile/redeem-banner.webp':'redeem-banner.webp'))" alt="" style="width: 100%; min-height: 125px;" />
      <p class="sidebanner-text">Redeem code</p>
      <div class="button-basic-tickets-menu-btn sidebanner-button">
        Send

        <div v-if="hasCode" class="notifications-count-badge" style="gap: 5px; --x:20px;">

        </div>
      </div>
    </div>
    <div :style="isMobile?'display: flex;row-gap: 5px; flex-direction: column;':'display: flex; flex-direction: row; gap:20px;'">
      <div class="sidebanner" @click="openChipsGuide">
        <img :src="require('../../assets/images/'+(isMobile?'mobile/raffles-banner.webp':'raffles-banner.webp'))" alt="" style="width: 100%; min-height: 125px;" />
        <p class="sidebanner-text" :style="isMobile?'':'font-size: 20px; width: 70%;'">How to get free Chips?</p>
        <div class="button-basic-tickets-menu-btn sidebanner-button blue">
          Learn
        </div>
      </div>
      <div class="sidebanner" @click="openLuckyWheel"
           style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 10px;"
      >

        <img :src="require('../../assets/images/'+(isMobile?'mobile/luckywheel-banner.webp':'luckywheel-banner.webp'))" alt=""
             style="width: 100%; min-height: 125px;position: absolute; top: 0; left: 0;"
        />
        <div class="sidebanner-text2" :style="isMobile?'':'font-size: 20px; width: 80%;'">{{'HOURLY\nLUCKY WHEEL'}}</div>
        <div class="sidebanner-text2" :style="isMobile?'':'font-size: 15px; width: 80%;'">{{'Available Soon\nto Spincity\nNFTs holders'}}</div>
        <!--div class="button-basic-tickets-menu-btn sidebanner-button blue">
          Play
        </div-->
        <transition name="slide-fade-dropdown">
          <div v-if="infoBubble"
               class="info-bubble-popup"
          >
            To be eligible to Hourly wheel complete the following :
            <br/>
            · subscribe to push notifications
            <br/>
            · Follow us on Twitter, Facebook, Telegram ( complete the task with zealy)
          </div>
        </transition>
        <p v-if="infoBubble!==undefined"
           class="info-bubble"
           @mouseenter="infoBubble=true;"
           @mouseleave="infoBubble=false;"
        >
          <svg width="6"
               height="12"
               viewBox="0 0 6 12"
               fill="white"
          >
            <path d="M4.45748 0C5.26148 0 5.66348 0.5472 5.66348 1.1742C5.66348 1.9572 4.96508 2.6814 4.05608 2.6814C3.29468 2.6814 2.85068 2.2314 2.87168 1.4874C2.87168 0.8616 3.40028 0 4.45748 0ZM1.98368 12C1.34888 12 0.883881 11.6088 1.32788 9.8856L2.05628 6.8304C2.18288 6.342 2.20388 6.1458 2.05628 6.1458C1.86608 6.1458 1.04288 6.483 0.555081 6.816L0.238281 6.288C1.78148 4.9764 3.55688 4.2078 4.31888 4.2078C4.95308 4.2078 5.05868 4.9716 4.74188 6.1458L3.90728 9.357C3.75968 9.924 3.82268 10.1196 3.97088 10.1196C4.16108 10.1196 4.78508 9.8844 5.39828 9.3954L5.75828 9.8838C4.25708 11.412 2.61728 12 1.98368 12Z" fill="white" />
          </svg>
        </p>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "AppBadge",
  components: {  },

  data() {
    return {
      isTest: false,
      redeemCode:"",
      isMobile : window.innerWidth <= 900,
      infoBubble:false,
    };
  },
  computed:{
    hasCode(){
      console.log("has code", window.location.search.includes('code='), !this.$store.getters.getRedeemedCodes.includes(this.redeemCode));
      return window.location.search.includes('code=') && !this.$store.getters.getRedeemedCodes.includes(this.redeemCode)
    }
  },
  mounted() {
    let url = window.location.hostname;
    if(url.includes("test.") || url.includes("localhost")) {
      this.isTest = true;
    }
    if(window.location.search.includes("code")){
      this.redeemCode = window.location.search.split("code=")[1]
      if(this.redeemCode.includes("&")) this.redeemCode = window.location.search.split("&")[0]
    }
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth <= 900;
    });
  },
  methods:{
    openGuide(){
      this.$store.commit("setGuideOpen", true)
    },
    openChipsGuide(){
      this.$store.commit("setChipsGuideOpen", true)
    },
    openLuckyWheel(){
      /*const state=this.$store.state;
      const getters=this.$store.getters;
      //let ii=1;
      //if (getters.getEnv==='test')
      {
        const loginData = JSON.parse(localStorage.getItem("loginData"));
        if (loginData){
          state.popupLuckyWheel.visible=true;
        }
        else{
          const errorPopupObj=state.errorPopupObj;
          errorPopupObj.errorPopup=true;
          errorPopupObj.titleErrorPopup=this.$t('luckyMsg.m_error');
          errorPopupObj.textErrorPopup=this.$t('luckyMsg.m052_09');
        }
      }//*/

    },
    openShop(){
      this.$store.commit("setShopPopupOpen", true)
    },
    openRedeem(){
      if(this.$store.getters.getUsername) {
        this.$store.commit("setRedeemPopupOpen", true);
      }else{
        this.$store.commit("setSignUpOpen", true);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.info-bubble-popup{
  top: 20px;
  left: 0;
  width: 100%;
  height: 90%;
  z-index: 2;
  white-space: break-spaces;
  font-size: 12px;
}
.info-bubble{
  background: linear-gradient(91.95deg, #FCC031 -9.33%, #FF2E2F 92.67%);
  border-radius: 50px;
  width: 24px;
  height: 24px;
  padding: 0px 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 200%;
  letter-spacing: -0.03em;
  text-align: center;
  margin-bottom: 10px;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 3;
}
.c-jackpot-badge__emoji {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 33px;
  z-index: 1;
  font-size: 30px;
}
.c-jackpot-badge__background1 {
  position: absolute;
  width: 505px;
  height: 260px;
  left: -44px;
  top: -29px;
}
.c-jackpot-badge__background2 {
  position: absolute;
  width: 454px;
  left: -17px;
  top: 11px;
}
.c-jackpot-badge__background3 {
  position: absolute;
  width: 420px;
  height: 202px;

  transform: matrix(1, 0, 0, -1, 0, 0);
}
.c-jackpot-badge {
  box-sizing: border-box;
  overflow: visible;
  border-radius: 30px;
  //background: linear-gradient(91.95deg, #FCC031 -9.33%, #FF2E2F 92.67%);
  display: flex;
  width: 420px;
  height: 202px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/images/img_win.webp");
  background-size: cover;
  cursor:pointer;
  filter: drop-shadow(2px 4px 6px orange);
  transition: $transition-medium;
  &:hover{
    filter: drop-shadow(0 0 15px orange) drop-shadow(2px 4px 6px #ff8800);
  }

  &:after {
    background: linear-gradient(102.76deg, #CC181A 3.6%, #FDEE64 53.89%, #CC181A 99.19%);
    content: '';
    width: 100%;
    height: 100%;
    padding: 2px;
    top: 0;
    left: 0;
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    border-radius: 30px;
    position: absolute;
    @include media-max(900px) {
      z-index: -1;
      height: 0;
      width: 0;
      opacity: 0;
    }

  }

  @include media-max(900px) {
    width: 100% !important;
  }


}
.c-jackpot-badge-shop {
  filter: drop-shadow(2px 4px 6px #00a6ff);
  background-image: url("../../assets/images/Banner_shop.webp");
  &:hover{
    filter: drop-shadow(0 0 15px #009dff) drop-shadow(2px 4px 6px #0048ff);
    z-index: 1;
  }

  &:after {
    background: linear-gradient(102.76deg, #183ccc 3.6%, #64fdfd 53.89%, #183ccc 99.19%);
    content: '';
    width: 100%;
    height: 100%;
    padding: 2px;
    top: 0;
    left: 0;
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    border-radius: 30px;
    position: absolute;
    @include media-max(900px) {
      z-index: -1;
      height: 0;
      width: 0;
      opacity: 0;
    }

  }
}

.c-jackpot-badge__title {
  /* jackpot */


  text-align: center;

  font-family: 'Rubik',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  margin: 0;
  /* identical to box height, or 37px */

  letter-spacing: -0.03em;
  text-transform: uppercase;

  /* white */

  color: #FFFFFF;

  text-shadow: 0 0 45px rgba(255, 255, 255, 0.3);
  z-index: 1;
}

.c-jackpot-badge__value {
  font-family: 'Rubik',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 115%;
  /* or 64px */

  text-align: center;
  letter-spacing: -0.03em;

  /* yellow */

  color: #FFE500;

  text-shadow: 0 0 8px rgb(149 135 0);
  z-index: 1;
}
.wrap{
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include media-max(900px) {
    justify-content: space-evenly;
    gap: 10px;
  }
}

.sidebanner{
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  height: 151px;

  @include media-max(900px) {
    height: unset;
    min-height: 125px;
  }
}

.sidebar{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 19px;
  width: 100%;

  @include media-max(900px) {
    gap: 2%;
    flex-direction: unset;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 5px;
  }
}
.sidebanner-text{
  position: absolute;
  left: 30px;
  top: 30px;
  width: 100%;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 120%;
  /* identical to box height, or 34px */

  letter-spacing: -0.03em;
  text-transform: uppercase;

  /* White */

  color: #FFFFFF;

  @include media-max(900px) {

    font-size: 6vw;
  }
}

.sidebanner-text2{
  /*left: 30px;
  top: 30px;/**/
  white-space: pre-wrap;
  position: relative;
  width: 100%;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 120%;
  /* identical to box height, or 34px */

  letter-spacing: -0.03em;

  /* White */

  color: #FFFFFF;

  @include media-max(900px) {

    font-size: 6vw;
  }
}
.sidebanner-button{
  position: absolute;
  left: 30px;
  bottom: 30px;
  margin: unset;
  transform:unset;
  width: unset;
  padding: 10px 30px;
}
.orange{
  background: linear-gradient(91.95deg, #FCC031 -9.33%, #FF2E2F 92.67%) var(--x, 0)/200%;
  box-shadow: 0 0 15px rgba(255, 136, 0, 0.5);
}
.green{
  background: linear-gradient(95.19deg, #0CFF7C -16.14%, #00AF90 137.96%) var(--x, 0)/200%;
  box-shadow: 0 0 15px rgba(123, 255, 0, 0.5);
}
.blue{
  background: linear-gradient(93.92deg, #00C6FB 0.27%, #005BEA 105.74%) var(--x, 0)/200%;
  box-shadow: 0 0 15px rgba(0, 234, 255, 0.5);
}
</style>
